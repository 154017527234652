import { Auth } from "aws-amplify"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { setAmplifyConfig } from "../../aws"
import { Navbar } from "../Navbar"

interface iProps {
  element: JSX.Element
}

export function ProtectedRoute({ element }: iProps): JSX.Element {
  const navigate = useNavigate()

  useEffect(() => {
    setAmplifyConfig()
    Auth.currentAuthenticatedUser().catch(() => navigate("/login"))
  }, [])

  return (
    <>
      <Navbar />
      {element}
    </>
  )
}
