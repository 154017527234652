import { PdsProgressLoadingIcon } from "@podium-design-system/react-icons"
import { Auth } from "aws-amplify"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { whiteColor } from "../../common/colors"
import { Button } from "../../common/components/Button"
import { Logo } from "../../common/components/Logo"
import { isEmail, isValidPassword } from "../../common/utils"

import "../Login/index.scss"

export function Registration(): JSX.Element {
    const navigate = useNavigate()

    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [repeatPassword, setRepeatPassword] = useState<string>("")
    const [passwordMatch, setPasswordMatch] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [canRegister, setCanRegister] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)

    const handleSignup = useCallback(() => {
        if (passwordMatch && username) {
            setLoading(true)
            Auth.signUp({
                username: username.toLowerCase(),
                password,
                autoSignIn: {
                    enabled: true,
                },
            })
                .then(() => navigate(`/confirm?email=${username.toLowerCase()}`))
                .catch((error) => {
                    setError(
                        error.message === "PreSignUp failed with error Cannot signup."
                            ? "Invalid email address, please contact Sportradar representative."
                            : error.message,
                    )
                })
                .finally(() => setLoading(false))
        }
    }, [username, password, passwordMatch])

    useEffect(() => {
        const doesPasswordMatch = repeatPassword !== "" && password !== "" && password === repeatPassword
        setPasswordMatch(doesPasswordMatch)
        setError(undefined)
        setCanRegister(username !== "" && doesPasswordMatch && isEmail(username) && isValidPassword(password))
    }, [password, repeatPassword, username])

    return (
        <div className="login">
            <Logo />
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault()
                    handleSignup()
                }}
            >
                <h2>Welcome</h2>
                <p>Please fill out form below to create an account.</p>
                <input id="email" placeholder="Email" value={username} onChange={(event) => setUsername(event.target.value)} />
                {username && !isEmail(username) && <small>Please enter valid email address.</small>}
                <input
                    id="password"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
                {password && !isValidPassword(password) && (
                    <small>
                        Password should be at least 8 characters long and should include 1 uppercase, 1 numeric and 1 special
                        character.
                    </small>
                )}
                <input
                    placeholder="Confirm password"
                    type="password"
                    value={repeatPassword}
                    onChange={(event) => setRepeatPassword(event.target.value)}
                />
                {password && repeatPassword && !passwordMatch && <small>Passwords do not match</small>}
                <div className="flex actions-right">
                    <Button
                        content={isLoading ? <PdsProgressLoadingIcon hexColor={whiteColor} /> : <span>Create an account</span>}
                        color="primary"
                        disabled={!canRegister || isLoading}
                    />
                </div>
                <br />
                {error && <small>{error}</small>}
            </form>
        </div>
    )
}
