import { Auth } from "aws-amplify"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "../../common/components/Button"
import { Logo } from "../../common/components/Logo"
import { isEmail } from "../../common/utils"

import "../Login/index.scss"

export function ForgotPassword(): JSX.Element {
    const navigate = useNavigate()

    const [username, setUsername] = useState<string>("")
    const [error, setError] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleRequestPasswordReset = useCallback(() => {
        try {
            setIsLoading(true)
            Auth.forgotPassword(username.toLowerCase())
                .then(() => navigate(`/reset-password?email=${username.toLowerCase()}`))
                .catch((error) => setError(error.message))
                .finally(() => setIsLoading(false))
        } catch (error) {
            setError((error as Error).message)
        }
    }, [username])

    return (
        <div className="login">
            <Logo />
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault()
                    handleRequestPasswordReset()
                }}
            >
                <h2>Forgotten password?</h2>
                <p>Enter your email to change your password</p>
                <input id="email" placeholder="Email" value={username} onChange={(event) => setUsername(event.target.value)} />
                {username && !isEmail(username) && <small>Please enter valid email address.</small>}
                <div className="flex actions-right">
                    <Button content={<span>Reset password</span>} color="primary" disabled={!isEmail(username) || isLoading} />
                </div>
                <br />
                {error && <small>{error}</small>}
            </form>
        </div>
    )
}
