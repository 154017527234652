import { Auth, Hub } from "aws-amplify"
import { useEffect, useState } from "react"
import { HubCapsule, HubPayload } from "@aws-amplify/core"
import { useNavigate } from "react-router-dom"

export function useAmplifyAuth() {
  const navigate = useNavigate()

  const [triggerFetch, setTriggerFetch] = useState(false)

  useEffect(() => {
    let isMounted = true

    const fetchUserData = async () => {
      if (isMounted) {
        try {
          const data = await Auth.currentAuthenticatedUser()
          if (data) {
            location.pathname === "/home" ? navigate(location.pathname) : navigate("/")
          }
        } catch (error) {
          if (!location.pathname.startsWith("/confirm")) navigate("/login")
        }
      }
    }

    const HubListener = () => {
      Hub.listen("auth", (data: HubCapsule) => {
        const { payload } = data
        onAuthEvent(payload)
      })
    }

    const onAuthEvent = (payload: HubPayload) => {
      switch (payload.event) {
        case "signIn":
          if (isMounted) {
            setTriggerFetch(true)
            console.log("signed in")
          }
          break
        case "autoSignIn":
          navigate("/")
          break
        default:
          return
      }
    }

    HubListener()
    fetchUserData().catch((error) => {
      console.log("Error fetching user data ", error)
    })

    return () => {
      Hub.listen("auth", () => {
        // do nothing
      })
      isMounted = false
    }
  }, [triggerFetch])

  const handleSignout = async () => {
    try {
      console.log("signed out")
      await Auth.signOut()
      setTriggerFetch(false)
      location.assign("/login")
    } catch (error) {
      console.error("Error signing out user ", error)
    }
  }

  return { handleSignout }
}
