import { Auth } from "aws-amplify"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Button } from "../../common/components/Button"
import { Logo } from "../../common/components/Logo"
import { isValidPassword } from "../../common/utils"

import "../Login/index.scss"

export function ResetPassword(): JSX.Element {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const username = searchParams.get("email")

    const [password, setPassword] = useState<string>("")
    const [repeatPassword, setRepeatPassword] = useState<string>("")
    const [passwordMatch, setPasswordMatch] = useState<boolean>(false)
    const [code, setCode] = useState<string>("")
    const [error, setError] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleResetPassword = useCallback(() => {
        try {
            if (username) {
                setIsLoading(true)
                Auth.forgotPasswordSubmit(username.toLowerCase(), code, password)
                    .then(() => navigate("/login"))
                    .catch((error) => setError(error.message))
                    .finally(() => setIsLoading(false))
            }
        } catch (error) {
            setError((error as Error).message)
        }
    }, [username, password, code])

    useEffect(() => {
        setPasswordMatch(repeatPassword !== "" && password !== "" && password === repeatPassword)
        setError(undefined)
    }, [password, repeatPassword])

    return (
        <div className="login">
            <Logo />
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault()
                    handleResetPassword()
                }}
            >
                <h2>Reset password</h2>
                <p>Please check your mailbox and enter the reset password code to proceed.</p>
                <input
                    name="code"
                    autoComplete="off"
                    placeholder="Reset password CODE"
                    value={code}
                    onChange={(event) => setCode(event.target.value)}
                />
                <input
                    placeholder="New password"
                    name="new-password"
                    autoComplete="new-password"
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
                {password && !isValidPassword(password) && (
                    <small>
                        Password should be at least 8 characters long and should include 1 uppercase, 1 numeric and 1 special
                        character.
                    </small>
                )}
                <input
                    placeholder="Confirm new password"
                    name="confirm-password"
                    autoComplete="off"
                    type="password"
                    value={repeatPassword}
                    onChange={(event) => setRepeatPassword(event.target.value)}
                />
                {password && repeatPassword && !passwordMatch && <small>Passwords do not match</small>}
                <div className="flex actions-right">
                    <Button
                        content={<span>Reset password</span>}
                        color="primary"
                        disabled={!code || !passwordMatch || isLoading}
                    />
                </div>
                <br />
                {error && <small>{error}</small>}
            </form>
        </div>
    )
}
