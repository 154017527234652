import "./index.scss"

export function Welcome(): JSX.Element {
  return (
    <div className="section-welcome">
      <span className="anchor" id="welcome" />
      <h2>
        More growth. <span className="red">More engagement.</span>
      </h2>
      <h4>Welcome to football. Reimagined.</h4>
      <p>Sportradar is the world’s leading sports technology company at the intersection of sports, media, and betting and we are in an unrivalled position to unlock substantial opportunities across all three sectors. Not only can we deliver significant commercial growth through our global distribution network, the largest in the industry including 900+ betting operators and 500+ media clients, we can grow your fanbase with highly engaged fans. Betting is a fundamental pillar of fan engagement, attracting new audiences to the EPL, EFL, and SFPL and engaging existing fans in new and deeper ways.</p>
      <p>Fast, accurate, deep, and contextualised data enables fans to connect with football in innovative ways across betting and media. It also enables you to better understand your fans, to provide a more unique and personalised experience, and to better monetise them.</p>
      <p>Protection of DataCo’s leagues will come from the watchful eye of our world-leading integrity services, including player protection and responsible gaming.</p>
      <p>With Sportradar as your partner, you’ll be scoring the winner in every match.</p>
    </div>
  )
}
