import "./index.scss"

interface IProps {
  color?: string
  content: JSX.Element
  onClick?: () => void
  disabled?: boolean
  id?: string
}

export function Button({ color = "red", content, onClick, disabled = false, id }: IProps): JSX.Element {
  return (
    <button
      className={`button button-${color}`}
      onClick={onClick}
      disabled={disabled}
      data-amplify-analytics-on="click"
      data-amplify-analytics-name="click"
      data-amplify-analytics-attrs={`id:${id}`}
    >
      {content}
    </button>
  )
}
