export function isTabletOrDesktop() {
  return window.outerWidth >= 768
}

export function isDesktop() {
  return window.outerWidth > 1024
}

export function isTablet() {
  return window.outerWidth <= 1024 && window.outerWidth >= 768
}

export function isEmail(email: string) {
  const emailRegex = "^[\\w\\-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$"
  return email.match(emailRegex) !== null
}

export function isValidPassword(password: string) {
  const passwordRegex = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\\d])(?=.*?[\\W]).{8,}$"
  return password.match(passwordRegex) !== null
}
