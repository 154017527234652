import { useEffect, useState } from "react"
import { downloadConfig } from "../../common/api"
import { VideoPlayer } from "../../common/components/VideoPlayer"
import { Config } from "../../common/types"
import { DegreeImpact } from "./components/DegreeImpact"
import { Ecosystem } from "./components/Ecosystem"
import { Footer } from "./components/Footer"
import { Hero } from "./components/Hero"
import { Intro } from "./components/Intro"
import { Welcome } from "./components/Welcome"

import "./index.scss"

export function Main(): JSX.Element {
  const [videoUrl, setVideoUrl] = useState<string>()
  const [config, setConfig] = useState<Config>()

  useEffect(() => {
    downloadConfig().then((response: Config) => setConfig(response))
  }, [])

  return (
    <>
      <div className="main">
        <Hero video={config?.video?.hero} />
        <Welcome />
        <Intro setVideoUrl={setVideoUrl} video={config?.video?.intro} />
        <DegreeImpact video={config?.video?.degree} poster={config?.poster?.degree}/>
        <Ecosystem
          setVideoUrl={setVideoUrl}
          pdf={config?.pdf}
          ppt={config?.ppt}
        />
        <Footer />
        {videoUrl && <VideoPlayer setVideoUrl={setVideoUrl} videoUrl={videoUrl} />}
      </div>
    </>
  )
}
