import "./index.scss"
import {Social} from "./Social";
import {Newsletter} from "./Newsletter";

export function Footer(): JSX.Element {
    return (
        <div className="section-footer">
            <Social/>
            <img className="logo" src="/assets/footer_logo.svg" alt="logo"/>

            <div className="flex footer">
                <div className="flex contacts">
                    <div className="col">
                        <p>Feldlistrasse 2</p>
                        <p>CH-9000 St. Gallen</p>
                        <p>Switzerland</p>
                        <br/>
                        <b>VAT identification number</b>
                        <p>CHE-113.910.142 MWST</p>
                    </div>
                    <div className="col">
                        <p>
                            <strong>Office:</strong>
                            <a href="tel:+41715177200">+41 71 517 72 00 </a>
                        </p>
                        <p>
                            <strong>Fax:</strong>
                            +41 71 517 72 99
                        </p>
                        <p>
                            <strong>E-Mail:</strong>
                            <a href="mailto:info@sportradar.com">info@sportradar.com</a>
                        </p>
                        <br/>
                        <p>
                            <strong>Press Enquiries:</strong>
                            <a href="mailto:press@sportradar.com">press@sportradar.com</a>
                        </p>
                    </div>
                </div>
                <Newsletter />
            </div>
        </div>
    )
}
