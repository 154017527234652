import { PdsProgressLoadingIcon } from "@podium-design-system/react-icons"
import { Auth } from "aws-amplify"
import { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useAmplifyAuth } from "../../common/auth"
import { whiteColor } from "../../common/colors"
import { Button } from "../../common/components/Button"
import { Logo } from "../../common/components/Logo"

import "../Login/index.scss"

export function ConfirmUser(): JSX.Element {
    const [searchParams] = useSearchParams()
    const username = searchParams.get("email")

    const [code, setCode] = useState<string>("")
    const [error, setError] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<string>()

    const handleConfirmSignup = useCallback(() => {
        if (username && code) {
            setIsLoading(true)
            Auth.confirmSignUp(username, code)
                .then(() =>
                    setSuccess("Your account was successfully verified. Please wait few seconds to be redirected to home page"),
                )
                .catch((error) => setError(error.message))
                .finally(() => setIsLoading(false))
        }
    }, [code, username])

    const handleResendActivationCode = useCallback(() => {
        if (username) {
            setIsLoading(true)
            Auth.resendSignUp(username)
                .then(() => setSuccess("Confirmation code was successfully reseted. Please check your email."))
                .catch((error) => setError(error.message))
                .finally(() => setIsLoading(false))
        }
    }, [username])

    useEffect(() => {
        setError(undefined)
        setSuccess(undefined)
    }, [code])

    useAmplifyAuth()

    return (
        <div className="login">
            <Logo />
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault()
                    handleConfirmSignup()
                }}
            >
                <h2>Please check your mailbox and enter the activation code to proceed.</h2>
                <p>Activation code should arrive in 15 minutes. Check spam folder if not received</p>
                <input id="code" placeholder="Activation CODE" value={code} onChange={(event) => setCode(event.target.value)} />
                <a className="hover" onClick={handleResendActivationCode}>
                    Resend activation code
                </a>
                <div className="actions-right flex">
                    <Button
                        content={isLoading ? <PdsProgressLoadingIcon hexColor={whiteColor} /> : <span>Confirm</span>}
                        color="primary"
                        onClick={handleConfirmSignup}
                        disabled={!code || !username || isLoading || error != undefined}
                    />
                </div>
                <br />
                {error && <small>{error}</small>}
                {success && <span>{success}</span>}
            </form>
        </div>
    )
}
