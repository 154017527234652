interface IProps {
    title: string
    type: "ppt" | "pdf"
    source: string
    className?: string,
    link?: string
    id: string
}

export function Feature({title, source, type, id, link, className = ""}: IProps): JSX.Element {
    return (
        <div className="feature"
             onClick={() => link && window.open(link, type === "pdf" ? "_blank" : "self" )}
             data-amplify-analytics-on="click"
             data-amplify-analytics-name="click"
             data-amplify-analytics-attrs={`id:${id}`}>
            <img src={source} alt={title} className={className}/>
            <h3>{title}</h3>
        </div>
    )
}
