import {PDF} from "../../../../common/types"
import {Feature} from "./Feature"

import "./index.scss"

interface IProps {
    setVideoUrl: (value?: string) => void
    pdf?: PDF,
    ppt?: string,
}

interface IFeature {
    source: string,
    type: "ppt" | "pdf",
    title: string,
    id: string,
    link?: string
}

export function Ecosystem({setVideoUrl, pdf, ppt}: IProps): JSX.Element {
    const features: IFeature[] = [
        {
            source: "/assets/betting.png",
            type: "pdf",
            title: "BETTING DATA PROPOSAL",
            id: "betting",
            link: pdf?.betting
        },
        {
            source: "/assets/odds.png",
            type: "ppt",
            title: "Presentation",
            id: "presentation",
            link: ppt
        },
        {
            source: "/assets/media.png",
            type: "pdf",
            title: "MEDIA DATA PROPOSAL",
            id: "media",
            link: pdf?.media

        }
    ]

    return (
        <>
            <div className="section-ecosystem" id="documents">
                <h2>Documents</h2>
                <div className="features">
                    {features.map(feature =>
                        (<Feature
                            title={feature.title}
                            key={feature.title}
                            type={feature.type}
                            source={feature.source}
                            id={feature.id}
                            link={feature.link}
                            className={feature.type === "pdf" ? "extra-padding" : undefined}
                        />))}
                </div>
            </div>
        </>
    )
}
