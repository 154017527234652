import {Fragment} from "react"
import {Feature} from "./Feature"

import "./index.scss"

interface  IProps {
    video?: string,
    poster?: string,
}

export function DegreeImpact({video, poster}: IProps): JSX.Element {
    const features = [
        {
            tag: "goalkeeper_card",
            title: "GOALKEEPER",
        },
        {
            tag: "defence_card",
            title: "DEFENCE",
        },
        {
            tag: "midfield_card",
            title: "MIDFIELD",
        },
        {
            tag: "attack_card",
            title: "ATTACK",
        }
    ]

    return (
        <div className="section-impact">
            <span className="anchor" id="tactics"/>
            <h2>
                Sportradar <span className="red">tactics</span>
            </h2>
            <p className="introduction">As the world&apos;s leading sports technology company, providing solutions across the global sports, betting and media ecosystem, Sportradar is uniquely positioned to support commercial growth, build and unlock audiences, and increase fan engagement all whilst delivering the highest standard of integrity and protection. With our committed investment to innovation, technology, and data, football fans will enjoy the ultimate experience.</p>
            {video && <video src={video} className="video" controls poster={poster}/>}
            <div className="features">
                {features.map((feature) => (
                    <Fragment key={feature.tag}>
                        <Feature tag={feature.tag} title={feature.title}/>
                    </Fragment>
                ))}
            </div>
        </div>
    )
}
