import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { useEffect } from "react"
import { setAmplifyConfig } from "./common/aws"
import { Main } from "./pages/Main"
import {ProtectedRoute} from "./common/components/ProtectedRoute";
import {Login} from "./pages/Login";
import {Registration} from "./pages/Registration";
import {ConfirmUser} from "./pages/ConfirmUser";
import {ForgotPassword} from "./pages/ForgotPassword";
import {ResetPassword} from "./pages/ResetPassword";

import "./App.scss"

function App() {
  useEffect(() => {
    setAmplifyConfig()
  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ProtectedRoute element={<Main />} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Registration />} />
          <Route path="/confirm" element={<ConfirmUser />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
