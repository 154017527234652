import { PdsProgressLoadingIcon } from "@podium-design-system/react-icons"
import { Auth } from "aws-amplify"
import { useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAmplifyAuth } from "../../common/auth"
import { whiteColor } from "../../common/colors"
import { Button } from "../../common/components/Button"
import { Logo } from "../../common/components/Logo"
import { isEmail } from "../../common/utils"

import "./index.scss"

export function Login(): JSX.Element {
    const navigate = useNavigate()

    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [error, setError] = useState<string>()
    const [canLogin, setCanLogin] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)

    const handleLogin = useCallback(() => {
        try {
            setLoading(true)
            Auth.signIn({ username: username.toLowerCase(), password })
                .catch((error) => {
                    if (error.message === "User is not confirmed.") {
                        navigate(`/confirm?email=${username.toLowerCase()}`)
                    } else {
                        setError(error.message)
                    }
                })
                .finally(() => setLoading(false))
        } catch (error) {
            setError((error as Error).message)
        }
    }, [username, password])

    useEffect(() => {
        setCanLogin(username !== "" && isEmail(username) && password.length > 0)
        setError(undefined)
    }, [username, password])

    useAmplifyAuth()

    return (
        <div className="login">
            <Logo />
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault()
                    handleLogin()
                }}
            >
                <h2>Welcome</h2>
                <p>Please provide your credentials to login.</p>
                <input placeholder="Email" value={username} onChange={(event) => setUsername(event.target.value)} />
                {username && !isEmail(username) && <small>Please enter valid email address.</small>}
                <input
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
                <div className="flex">
                    <div className="vertical">
                        <Link to="/signup">Register to access the material</Link>
                        <Link to="/forgot-password">Forgotten password?</Link>
                    </div>
                    <Button
                        content={isLoading ? <PdsProgressLoadingIcon hexColor={whiteColor} /> : <span>Sign in</span>}
                        color="primary"
                        disabled={!canLogin || isLoading}
                    />
                </div>
                <br />
                {error && <small>{error}</small>}
            </form>
        </div>
    )
}
