import { Amplify, Analytics, Auth } from "aws-amplify"

interface Navigator {
  userAgentData?: {
    mobile: boolean
    platform: string
  }
}

export function setAmplifyConfig() {
  const config = {
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_REGION ?? "",
      userPoolId: process.env.REACT_APP_USER_POOL_ID ?? "",
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID ?? "",
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID ?? "",
      cookieStorage: {
        domain: window.location.hostname,
        secure: true,
        path: "/",
        expires: 365,
        sameSite: "none",
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: process.env.REACT_APP_ANALYTICS_APP_ID ?? "",
        region: process.env.REACT_APP_REGION ?? "",
        mandatorySignIn: false,
        autoSessionRecord: false,
      },
    },
  }

  try {
    Analytics.autoTrack("session", {
      enable: false,
    })
    Amplify.configure(config)

    Analytics.autoTrack("event", {
      enable: true,
      events: ["click"],
      selectorPrefix: "data-amplify-analytics-",
      provider: "AWSPinpoint",
      attributes: {
        device: navigator.userAgent,
        mobile: (navigator as Navigator).userAgentData?.mobile,
        platform: (navigator as Navigator).userAgentData?.platform,
      },
    })
  } catch (error: unknown) {
    console.log("There was an error with configuring Amplify")
    console.log((error as Error).message)
  }
}

export async function currentUserToken() {
  return Auth.currentAuthenticatedUser().then((user) => {
    return user.signInUserSession.accessToken.jwtToken
  })
}
