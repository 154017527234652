import { PdsArrowRightIcon } from "@podium-design-system/react-icons"
import { whiteColor } from "../../../../common/colors"
import { Button } from "../../../../common/components/Button"

export function Newsletter(): JSX.Element {
  return (
    <div className="newsletter">
      <p>Sign up to our newsletter and never miss an update.</p>
      <Button
        id="newsletter"
        content={
          <>
            Sign up &nbsp; <PdsArrowRightIcon hexColor={whiteColor} fixedSize="24px" />
          </>
        }
        onClick={() => window.open("https://sportradar.com/newsletter/", "_blank")}
      />
    </div>
  )
}
