import { PdsCloseXIcon } from "@podium-design-system/react-icons"
import { MouseEvent, useCallback, useEffect, useRef } from "react"
import { whiteColor } from "../../colors"

import "./index.scss"

interface IProps {
  setVideoUrl: (value?: string) => void
  videoUrl?: string
}

export function VideoPlayer({ setVideoUrl, videoUrl }: IProps): JSX.Element {
  const video = useRef<HTMLVideoElement>(null)

  const handleClickOutside = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (event.target !== video.current) {
        setVideoUrl(undefined)
      }
    },
    [video],
  )

  const isEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && videoUrl) {
        setVideoUrl(undefined)
      }
    },
    [videoUrl],
  )

  useEffect(() => {
    document.addEventListener("keydown", isEscape, true)
    return () => {
      document.removeEventListener("keydown", isEscape, true)
    }
  }, [])

  if (!videoUrl) {
    return <></>
  }

  return (
    <div className="video-player" onClick={handleClickOutside}>
      <div className="close">
        <PdsCloseXIcon hexColor={whiteColor} fixedSize="32px" onClick={() => setVideoUrl(undefined)} />
      </div>
      <video ref={video} src={videoUrl} autoPlay controls controlsList="nodownload" />
    </div>
  )
}
