import "./index.scss"

interface IProps {
  video?: string
}

export function Hero({ video }: IProps): JSX.Element {
  return (
    <div className="section-hero">
      <video src={video} poster="/assets/hero_poster.png" autoPlay muted loop playsInline />
      <div className="title-hero">
          <h2>DATACO & SPORTRADAR</h2>
          <h2>A <span className="red">WINNING</span> FORMATION.</h2>
      </div>
    </div>
  )
}
