import axios from "axios"

export async function downloadConfig() {
  try {
    const response = await axios.get(process.env.REACT_APP_CONFIG_URL ?? "", { withCredentials: true })
    return response.data
  } catch (error) {
    return console.log(error)
  }
}
